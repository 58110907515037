* {
    color: white;
}

.container {
    position: absolute;
    padding: 0 3rem;
    max-width: 100vw;
    height: 100vh;

    h2 {
        font-size: 6rem;
    }
}

section {
    position: relative;
    height: 100vh;
}

.background-image {
    background-image: url('../../../assets/kids.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(0.7);
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 0;
}

.gradient {
    @extend .background-image;
    background: linear-gradient(90deg, #0000008c 0%, rgba(33, 33, 33, 0) 100%);
}