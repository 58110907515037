.container-services2 {
    padding: 20px;
    background-color: #f9f9f9;

    h2 {
        text-align: center;
        color: #333;
        font-weight: 800;
        font-size: 3rem;
        margin-bottom: 2rem;
    }
}

.carousel {
    position: relative;
    width: 80%;
    margin: auto;
    overflow: hidden;

    .carousel-content {
        display: flex;
        transition: transform 0.5s ease-in-out;

        .slide {
            min-width: 100%;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .slide.active {
            opacity: 1;
            position: relative;
        }
    }

    .prev,
    .next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
        font-size: 2rem;
        z-index: 1;

        &:focus {
            outline: none;
        }
    }

    .prev {
        left: 0;
    }

    .next {
        right: 0;
    }
}

.proyectos-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff;

    .image-section {
        flex: 1;
        padding: 20px;

        img {
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }

    .content-section {
        flex: 1;
        padding: 20px;

        h1 {
            font-size: 2rem;
            color: #000;
            font-weight: 800;

            .highlight {
                color: #f4c10f;
            }
        }

        p {
            margin: 20px 0;
            font-size: 1rem;
            color: #666;
        }
    }
}