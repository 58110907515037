.primary {
    background-color: #77ae9f;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px;
    text-align: center;
    width: 120px;
}

.secondary {
    border-radius: 10px;
    border: 1px solid white;
    cursor: pointer;
    padding: 10px;
    text-align: center;
    width: 120px;
}