.container-allies {
    background-color: white;
    padding: 20px;
    display: flex;
    gap: 2rem;
    justify-content: center;
    width: 100%;
}

.container-services {
    align-items: center;
    background-color: #F2F3F6;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0 20px;
    position: relative;

    p {
        color: gray;
        font-weight: 300;
    }

    h2 {
        align-self: start;
        color: black;
        font-weight: 700;

    }
}

.container-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 300px;
}

.capacitation {
    background-image: url('https://img.freepik.com/foto-gratis/mujer-negocios-empoderada-que-trabaja-ciudad_23-2149589061.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 2rem;
    filter: brightness(0.7);
    padding-top: 56.25%;
    position: relative;
    width: 100%;

    h3 {
        position: absolute;
        bottom: 10px;
        left: 10px;
        z-index: 1;
        color: white;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 2rem;
        background: linear-gradient(0deg, #000000b8 0%, rgba(33, 33, 33, 0) 100%);
        z-index: 0;
    }
}

.iconos {
    height: 5.3125rem;
}

.title {
    h2 {
        color: #112031;
    }

    b {
        color: #77ae9f;
    }
}