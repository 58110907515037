.circle-icon {
    border-radius: 100%;
    background-color: white;
    width: max-content;
    padding: 1rem;
    box-shadow: 0px 11px 22px -10px rgba(0, 0, 0, 0.75);


    img {
        filter: invert(0%) sepia(21%) saturate(0%) hue-rotate(69deg) brightness(0%) contrast(104%);
    }
}