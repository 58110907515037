.footer {
    .call-to-action {
        background-color: #A1C5C4;
        text-align: center;
        padding: 60px 20px;

        h1 {
            color: #112031;
            font-size: 3rem;
            font-weight: 800;
            margin-bottom: 20px;
        }

        p {
            color: #112031;
            font-size: 1.5rem;
            margin-bottom: 30px;
        }

        button {
            background-color: #edf3e4;
            color: #115559;
            border: none;
            padding: 15px 30px;
            font-size: 1.2rem;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #ddd;
            }
        }
    }

    .footer-main {
        background-color: #faf8f8;

        color: #0e0101;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .footer-logo {
            text-align: center;
            margin-bottom: 20px;
            width: 100%;

            img {
                width: 100px;
                margin: 0 auto;
                margin-bottom: 10px;
            }

            p {
                color: #112031;
                margin: 0;
            }
        }

        .footer-links {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            width: 100%;
            max-width: 1200px;

            .footer-column {
                flex: 1;
                min-width: 200px;
                margin: 10px;
                text-align: center;

                h3 {
                    color: #000;
                    font-size: 1.5rem;
                    margin-bottom: 10px;
                }

                p {
                    color: #000;
                    margin: 5px 0;
                }

                .social-icons {
                    align-items: center;
                    display: flex;
                    justify-content: center;

                    a {
                        color: #000;
                        margin: 0 10px;
                        font-size: 1.5rem;
                        transition: color 0.3s;

                        #whatsapp {}

                        img {
                            height: 68px;
                        }

                        &:hover {
                            color: #007bff;
                        }
                    }
                }
            }
        }
    }
}